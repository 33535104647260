
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend<Data, Methods, Computed, Props>({
  computed: {
    ...mapGetters('RouteHistoryStore', ['getPreviousPage']),
  },
  methods: {
    goBack() {
      if (!this.getPreviousPage.name) {
        this.$router.push('/')
      } else {
        this.$router.back()
      }
    },
  },
})

interface Props {}
interface Data {}
interface Computed {
  getPreviousPage: {
    fullPath: string
    name: string | null
    path: string
  }
}
interface Methods {
  goBack: () => void
}
