
import Vue from 'vue'
import NaverDelivery from '../../views/nDelivery/NaverDelivery.vue'

export default Vue.extend<Data, Methods, Computed>({
  components: { NaverDelivery },
})

interface Data {}
interface Computed {}
interface Methods {}
